export const Days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
export const TrueFalse = ['True', 'False'];
export const Cities = ['Mississauga', 'Oakville', 'Milton', 'Brampton', 'Toronto', 'Burlington'];
export const cuisines = [
  'American',
  'Carribbean',
  'Thai',
  'Spanish',
  'British',
  'Central Asian',
  'Eastern European',
  'Pakistani',
  'Maghreb',
  'Canadian',
  'Cajun',
  'Moroccan',
  'Levantine',
  'Cuban',
  'European',
  'Japanese',
  'Italian',
  'Mediterranean',
  'German',
  'Arab',
  'Indonesian',
  'Brazilian',
  'Turkish',
  'Russian',
  'Middle Eastern',
  'Haitian',
  'Lebanese',
  'North African',
  'Irish',
  'Chinese',
  'Indian',
  'African',
  'French',
  'Latin American',
  'South American',
  'West African',
  'Greek',
  'Mexican',
  'Egyptian',
  'Korean',
  'Scottish',
  'Portuguese'
];
