/*
  Imports
*/
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography
} from '@material-ui/core';
import React, { useState } from 'react';

import Dialog from 'src/components/misc/alerts/Dialog';
import { OrderListIcon } from 'src/config/icons';
import { ORDER_STATUS, ORDER_STATUS_ORDER, rowsPerPageList } from 'src/config/settings';
import bookingService from 'src/services/BookingServiceClass';
import userService from 'src/services/UserService';
import palette from 'src/theme/palette';
import { getDateTime } from 'src/utils/dateTime';
import SearchNotFound from '../../../components/misc/alerts/SearchNotFound';
import { UserListHead, UserListToolbar } from '../../../components/misc/table';
import Scrollbar from '../../../components/Scrollbar';

/*
    Global Variables, Functions
    always and new
  */

/*
    Main Working
  */

export default ({ data }) => {
  /*
      States, Params, Navigation, Query, Variables.
    */
  const TableHeader = [
    { id: 'id', label: '#', align: 'left' },
    { id: 'name', label: 'Name', align: 'left' },
    { id: 'quantity', label: 'Quantity', align: 'left' }
  ];

  /*
      Main Design.
    */
  return (
    <>
      <Card>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 400 }}>
            <Table>
              <UserListHead headLabel={TableHeader} rowCount={data.length} hideCheckBoxes={true} />
              <TableBody>
                {data.map((row, id) => {
                  const { quantity, name } = row;
                  return (
                    <TableRow
                      key={id}
                      tabIndex={-1}
                      role="checkbox"
                      style={{
                        backgroundColor: palette.background.hover,
                        textDecoration: 'none'
                      }}
                    >
                      <TableCell padding="checkbox"></TableCell>
                      <TableCell>{id + 1}</TableCell>
                      <TableCell>
                        <Typography variant="subtitle2" noWrap>
                          {name}
                        </Typography>
                      </TableCell>
                      <TableCell align="left">{quantity}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Scrollbar>
      </Card>
    </>
  );
};
